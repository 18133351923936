import { Entity } from '@jobilla/entity';
import { CardNetwork } from '@entities/payment/card-network.enum';
import { CountryCode } from '@services/geo/geo.service';

export class WorkerBankingDetails extends Entity {
  public id: number = null;
  public workerId: number = null;
  public accountTitle: string = null;
  public accountNumber: string = null;
  public cardNumber: string = null;
  public cardNetwork: CardNetwork = null;
  public routingNumber: string = null;
  public institutionNumber: string = null;
  public interacEmail: string = null;
  public externalAccountId: string = null;
  public countryCode: CountryCode = null;
  public createdBy: number = null;
  public createdAt: string = null;
  public updatedBy: number = null;
  public updatedAt: string = null;
}
